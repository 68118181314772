import React from 'react';
import './timeline.css'

const getDate = (date) => {
    return date.split("–")
}

const TimelineItem = (item,i) => {
    const itemDate = getDate(item.date)
    return (
        <div key={i} className="timeline-item">
           
            <div>
            <h4 className="timeline-date"><span className="countdown">{itemDate[0]}</span><span className="the-date">{itemDate[1]}</span></h4>
            <h2>{item.label}</h2>
            {
                item.desc && (
                    <p>{item.desc}</p>
                )
            }
            </div>
        </div>
    )
}

export default class Timeline extends React.Component {
    constructor() {
      super()
      this.state = {
          milestones:[
              {label:"Gør det", date:"In 15 days – 25. aug 2019"},
              {label:"Gør det i morgen",desc:"Heyhooh warning of the all",date:"In 17 days – 25. aug 2019"},
              {label:"Gør det nu",date:"In 31 days – 25. aug 2019"},
              {label:"Gør det aldrig asdf adsf asf ",date:"In 156 days – 25. aug 2019"},
              {label:"Gør det altid sadfsdaf dsf",date:"In 155 days – 25. aug 2019"},
              {label:"Gør det sd fs fasf sad", date:"In 15 days – 25. aug 2019"},
              {label:"Gør det i morgen sdfdsf sdf sa sdf sf sadf ",date:"In 17 days – 25. aug 2019"},
              {label:"Gør det nu sdf sdf sadf sf ewgwe gwqeg ",date:"In 31 days – 25. aug 2019"},
              {label:"Gør det aldrig qewgw w ew ew we weg  dgs",date:"In 156 days – 25. aug 2019"},
              {label:"Gør det altida sdgsadg dsg sdg asdg adg s",date:"In 155 days – 25. aug 2019"},
              {label:"Gdsghkgdkh gdkshg d sdh s ", date:"In 15 days – 25. aug 2019"},
              {label:"gdh gs hdgsg det i morgen",date:"In 17 days – 25. aug 2019"},
              {label:"Gør dfsgfgsdfgsdfg nu",date:"In 31 days – 25. aug 2019"},
              {label:"Gør det dfsgfgerwy",date:"In 156 days – 25. aug 2019"},
              {label:"Gør det altid",date:"In 155 days – 25. aug 2019"},
              {label:"Gør reyrey eryw", date:"In 15 days – 25. aug 2019"},
              {label:"Gør det i morgen",date:"In 17 days – 25. aug 2019"},
              {label:"Gør eryeryweyrerywry nu",date:"In 31 days – 25. aug 2019"},
              {label:"hfdhsdhsdh det aldrig",date:"In 156 days – 25. aug 2019"},
              {label:"Gør det sdfhdfsh",date:"In 155 days – 25. aug 2019"},
          ]
      }
    }
    render() {
        return (
            <div className="a-timeline">
                <div className="the-line"></div>
                {this.state.milestones.map(TimelineItem)}
            </div>
        )
    }
}
