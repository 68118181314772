const membershipMan = "https://beta-resource.asosio.com/communities/264/walls/1012/object/object_3697636907396710400"

class _ASOSIOcache {
    constructor() {
        this.data = false
        this.request = ({wid,type,id}) => {
            return fetch(`https://beta-resource.asosio.com/communities/264/walls/${wid}/${type}/${id}`,{
                headers: {
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjVGtuIiwid2FsbHMiOlsxMDExLDEwMjAsMTAxOSwxMDE4LDEwMTcsMTAxNiwxMDEyLDEwMTUsMTAxNCwxMDEzLCJoYW5kYm9vayIsIm5ld3MiXSwibW9kdWxlcyI6WyJoYW5kYm9vayJdLCJ1aWQiOjIsImVtYWlsIjoiZEBhc29zaW8uY29tIiwiY2lkIjoyNjQsInJvbGUiOiJhZG0iLCJpYXQiOjE1NzU0OTc1MjgsImV4cCI6MTU3NTUxOTEyOH0.HhJFwtYCKhOp-4oXI1qO6chPH-CFA9fIbfC9oNg2QVw"
                }
            }).then((rsp) => rsp.json())
        }
        this.fetch = () => {
            fetch("https://beta-resource.asosio.com/communities/264/walls/1012/object/object_3697636907396710400",{
                headers: {
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjVGtuIiwid2FsbHMiOlsxMDExLDEwMjAsMTAxOSwxMDE4LDEwMTcsMTAxNiwxMDEyLDEwMTUsMTAxNCwxMDEzLCJoYW5kYm9vayIsIm5ld3MiXSwibW9kdWxlcyI6WyJoYW5kYm9vayJdLCJ1aWQiOjIsImVtYWlsIjoiZEBhc29zaW8uY29tIiwiY2lkIjoyNjQsInJvbGUiOiJhZG0iLCJpYXQiOjE1NzU0OTc1MjgsImV4cCI6MTU3NTUxOTEyOH0.HhJFwtYCKhOp-4oXI1qO6chPH-CFA9fIbfC9oNg2QVw"
                }
            }).then((rsp) => rsp.json()).then((rsp) => {
                
                const indexObject = rsp.data
                const index = indexObject && JSON.parse(indexObject.content)
                this.data = indexObject
                this.index = index
                index.forEach((entry, index) => {
                    this.request({
                        wid:indexObject.wid,
                        type:"post",
                        id: entry.id
                    }).then((rsp) => {
                        this.index[index] = {title:rsp.data.title,content:rsp.data.content}
                        rsp.data.content.forEach((id, contentIndex) => {
                            this.request({
                                wid:indexObject.wid,
                                type:"text",
                                id
                            }).then((rsp) => {
                                this.index[index] = Object.assign({},this.index[index],{
                                    content: this.index[index].content.map((id,i) => i === contentIndex ? rsp.data.content : id)
                                })
                                console.log(this.index)
                            })
                        })
                        
                    })
                })

            })
        }
    }
}
const ASOSIOcache = new _ASOSIOcache()
export default ASOSIOcache