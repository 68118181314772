import React from 'react';

export default class Inject extends React.Component {
    constructor() {
        super()
        this.state = {
            stylesheet:false
        }
        this.fetchStylesheet = (stylesheet) => {
            var head = document.head;
            var link = document.createElement("link");
            
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "/communities/25/styles";
            
            head.appendChild(link);
            this.setState({
                stylesheet
            })
        }
        this.fetchStyle = (cid) => {
            
            //fetch('/communities/25/styles').then(rsp => rsp.text()).then((data) => this.setStylesheet(data))
        }
    }
    componentDidMount() {
        if ( ! this.state.stylesheet) {
            this.fetchStylesheet()
        }
    }
    componentWillUnmount() {

    }
    render() {
        return this.props.children
    }
}