import React from 'react';
import RandomQuote from './RandomQuote'
import './Colourz.css'
const makeColor = (i) => 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')'
//const colors = [...Array(60).keys()].map(() => '#'+Math.floor(Math.random()*16777215).toString(16))
const colors = [...Array(60).keys()].map(makeColor)
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export function ManyColors() {
    return (
        <React.Fragment>
            <div style={{position:"fixed",overflow:"hidden",boxShadow:"0 5px 25px rgba(0,0,0,0.3)",transformStyle: "preserve-3d", transform:"",opacity:"0.7"}}><Colourz /></div>
            <div style={{overflow:"hidden",boxShadow:"0 5px 25px rgba(0,0,0,0.3)",transformStyle: "preserve-3d", transform:"scale(0.85)"}}><Colourz /></div>
        </React.Fragment>
    )
    return (
        <div>
            {[...Array(9)].map((_,i) => {
            return (<div style={{transform:"scale(0.5)"}}><Colourz /></div>)
            })}
        </div>
    )
}

const injectedStyles = `
body {
    background:#f1f1f1;
    background: radial-gradient(circle, rgba(255,255,255,1) 40%, rgb(211, 210, 210) 100%); 
}
@keyframes zoom {
    0% {
         transform: scale(1);
    }
    50% {
        transform: scale(1.5);
        opacity:0;
   }
   75% {
       opacity: 0.5;
   }
    100% {
        opacity:0.8;
         transform: scale(1);
    }
 }
 @keyframes spinCube {
    0% { transform: translateZ(-100px) rotateX(  0deg) rotateY(  0deg); }
  100% { transform: translateZ(100px) rotateX(360deg) rotateY(360deg); }
}
.spin-it {
    animation: spinCube;
    animation-duration: 20s;
  animation-iteration-count: infinite;
}
.bg-image {
    animation-name: zoom;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}
@keyframes moveup {
    0% {
        transform: translateX(-100%);
    }
    33.33% {
        transform: translateX(0px);
   }
   66.66% {
        transform: translateX(0px);
        opacity:1;
    }
    100% {
        opacity:0;
        transform: translateX(100%);
    }
 }
.quote {
    animation-name: moveup;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
h1 {
    text-shadow: 0 5px 25px rgba(0,0,0,0.2);
    transform: rotateY(-45deg);
}`
const fetchStyleDept = (url) => {
    var head = document.head;
    var link = document.createElement("link");
    
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = url;
    
    head.appendChild(link);
}
const injectStyle = (styles) => {
    var body = document.body;
    var style = document.createElement("style");
    
    style.type = "text/css";
    style.cssText = styles
    
    body.appendChild(style);
}

export default class Colourz extends React.Component {
    constructor() {
        super()
        this.state = {
            bg:0,
            quotes: shuffle(RandomQuote)
        }
        this.timer = () => {
            this.forceUpdate()
        }
        this.changebg = () => {
            this.setState({bg:this.state.bg + 1})
        }
        this.randomFont = () => {
            const number = Math.floor(Math.random() * 6)
            return "t-"+number
        }
        this.image = () => {
            return "https://picsum.photos/1920/1080/?blur=5&x="+this.state.bg
        }
        this.nextImage = () => {
            return "https://picsum.photos/1920/1080/?blur=5&x="+(this.state.bg + 1)
        }
    }
    componentDidMount() {
        setInterval(this.timer, 5000)
        setInterval(this.changebg, 10000)
        injectStyle(injectedStyles)
        fetchStyleDept("https://fonts.googleapis.com/css?family=Lalezar&display=swap")
    }
    render() {
        const date = new Date()
        const hours = date.getHours()
        const mins = date.getMinutes()
        const seconds = date.getSeconds()

        return (
        <div style={{overflow:"hidden",fontFamily:"'Lalezar', cursive",transition:"1000ms",height:window.innerHeight,width:window.innerWidth,background:colors[seconds]}}>
            <h1 className={"quote"} style={{textAlign:"center",position:"fixed",width:"100%",boxSizing:"border-box",fontSize:"4.8rem",lineHeight:"5rem",padding:"100px 200px",color:"#fff",marginTop:"14%",zIndex:"9999"}}>{this.state.quotes[this.state.bg]}</h1>
            <div className="bg-image" style={{
                transition:"1000ms",
                height:window.innerHeight,
                opacity:"0.6",
                width:window.innerWidth,
                backgroundSize:"cover",
                backgroundImage:"url("+this.image()+")"}}></div>
            {
                false && (<div style={{opacity:0,backgroundImage:"url("+this.nextImage()+")"}}></div>)
            }
        </div>
        )
    }
}