import React from 'react';
import './App.css';
import Bottleneck from "bottleneck";
import loader from './three-dots.svg'
import Inject from './StyleInjector/Inject'
import StyleEditor from './StyleInjector/StyleEditor'


class App extends React.Component {
  constructor() {
    super()
    this.state = {
      parseing:false,
      csvFile:false,
      headers:false
    }

    this.startImport = () => {
      this.setState({importing:true,submittingIndex:0})

      const onId = this.state.onId
      const headers = this.state.resolvedHeaders
      const nextImport = () => this.setState({submittingIndex:this.state.submittingIndex + 1})

      const limiter = new Bottleneck({
        maxConcurrent: 1,
        minTime: 420
      });

      Promise.all(this.state.content.map(function (row) {

        // set up our request
        var options = {
            method: 'POST',
            headers:{
              "content-type":"application/json"
            },
            body: JSON.stringify(row.reduce((res, r, i) => {
              res[headers[i].name] = r
              return res
            },{}))
        };
    
        return limiter.schedule(() => fetch(('/api/saveObjectOn/' + onId), options))
            .then(function (response) {
              nextImport()
            })
            .catch(function (err) {
                // API call failed...
            });
    })).then(() => {
      this.setState({importSuccess:true})
    })
    } 
    this.createNewDatabase = () => {

      const constructContent = (headers) => {
        return headers.map((h) => Object.assign({},h,{name:h.header.replace(" ", "-").toLowerCase()+"#"+Math.random().toString(36).substring(7)}))
      }

      const resolvedHeaders = constructContent(this.state.assignedHeaders)

      const body = {
        type:"object",
        arrity:"form",
        content:JSON.stringify(resolvedHeaders)
      }
      console.log('create new databatse', body)
      fetch('/api/saveHeaders', {
        method: 'POST',
        headers:{
          "content-type":"application/json"
        },
        body: JSON.stringify(body)
      }).then(rsp => rsp.json()).then((rsp) => {
        this.setState({onId:rsp.data.id,startDataImport:true,resolvedHeaders})
      })


    }
    this.uploadCSV = (e) => {
      this.setState({parseing:true})
      const data = new FormData()
      data.append('file', e.target.files[0])

      fetch('/api/parseHeaders', {
        method: 'POST',
        body: data
      }).then(rsp => rsp.json()).then((rsp) => this.setState({
        headers:rsp.data.headers,
        content:rsp.data.content,
        count:rsp.count
      }))
    }
    this.assignHeader = (header, i, ofType) => {
      const headers = this.state.assignedHeaders || []
      headers[i] = {index: i, header, ofType}
      this.setState({assignedHeaders:headers})
    }
    this.removeRow = (i) => {
      if ( ! window.confirm("Remove row?")) return null
      this.setState({content:this.state.content.filter((_,index) => index !== i)})
    }
  }
  render() {
    const withFieldName = (i) => {
      return this.state.headers[i]
    }
    const previewContent = (content) => {
      return (
        <React.Fragment>
      <ul className={"value-row " + (this.state.resolvedHeaders && "row-"+ this.state.resolvedHeaders.reduce((count) => {
        return count + 1
      },0))}><li><ul><li className="remove-row">&nbsp;</li>{this.state.headers.map((header,i) => this.state.resolvedHeaders && this.state.resolvedHeaders[i] && (
    <li key={i} style={{background:"rgba(0,0,0,0.1)"}}>{header}</li>
      ))}</ul></li>{content.slice(0,500).map((row, i) => (
      <li key={i}><ul><li className="remove-row" onClick={() => this.removeRow(i)}>&times;</li>{row.map((field,index) => this.state.resolvedHeaders && this.state.resolvedHeaders[index] && (<li key={index}>{field || "–"}</li>))}</ul></li>
      ))}{content.length > 500 && (
        <li className="more-rows">and {content.length - 500} more rows</li>
          )}</ul>
      
      </React.Fragment>
      )
    }
    const importData = () => {
      return (
        <React.Fragment>
          {
            this.state.submittingIndex >= 0 ? (
              <ul className="value-row"><li style={{textAlign:"center",padding:"20px"}}><img src={loader} /><br /> <br />Importing row <span className="bold">{this.state.submittingIndex}</span> of <span className="bold">{this.state.content.length}</span></li></ul>
              ) : this.state.content && previewContent(this.state.content)
          }
          
          <a className="btn" onClick={() => this.startImport()}>Start import process</a>
        
        </React.Fragment>
      )
    }
    const createDatabase = () => {
      if (this.state.startDataImport) {
        return (
          <a className="btn" id="create-database" onClick={() => this.createNewDatabase()}>Create database</a>
        )
      }
      return (
        <a className="btn" onClick={() => this.createNewDatabase()}>Create database</a>
      )
    }
    const content = () => {
      if (this.state.importSuccess) {
        return (
          <div>
          <h1>Import success!</h1>
          <h2>Imported {this.state.submittingIndex + 1} rows</h2>
          <p>Check out the database here</p>
          </div>
        )
      }
      if (this.state.importing) {
        return importData()
      }
      if (this.state.startDataImport) {
        return importData()
      }
      if (this.state.headers) {
        const formatHeader = (h,i) => (
          <div>
      <select name={"select-"+h.replace(" ","_")} onChange={(e) => this.assignHeader(h,i,e.target.value)}>{[
        {label:"--- Select the format of the column"},
        {label:"Question",type:"question"},
        {label:"Choice",type:"choice"},
        {label:"---"},
        {label:"Don't import field",type:"ignore"}
      ].map((o,i) => <option key={i} value={o.value}>{o.label}</option> )}</select>
          </div>
        )
        return (
          <div>
        <ul className="headers">{this.state.headers.map((h,i) => (<li key={i}><span className="small">Header</span>{h}{formatHeader(h,i)}</li>))}</ul>
          {
            
          }
          {
            createDatabase()
          }
          </div>
          )
      }
      if (this.state.parseing) return (
        <h3>Uploading...</h3>
      )
      return (
        <input type="file" label="upload file" id="upload-file" onChange={this.uploadCSV} />
      )
    }
    return (
      <Inject>
      <div className="App">
  <div>Logged in as {window.acontext && window.acontext.user}</div>
        <h2>Create a new database by uploading a CSV file</h2>
        {
          content()
        }
        <StyleEditor />
      </div>
      </Inject>
    );
  }
}

export default App;
