export default [
,"You are the only one who can limit your greatness."
,"Don’t wish it were easier. Wish you were better."
,"He who is not courageous enough to take risks will accomplish nothing in life."
,"Don’t wait for opportunity. Create it."
,"The struggle you’re in today is developing the strength you need for tomorrow."
,"Strength is the product of struggle. You must do what others don’t to achieve what others wont."
,"You have to be at your strongest when you’re feeling at your weakest."
,"You don’t want to look back and know you could have done better."
,"Tough times don’t last. Tough people do. – Robert H. Schuller"
,"Your mind is a powerful thing. When you fill it with positive thoughts, your life will start to change."
,"We may encounter many defeats but we must not be defeated. – Maya Angelou"
,"With confidence you have won before you have started. – Marcus Garvey"
,"Don’t let your fear decide your future. – Shalane Flanagan"
,"Goals may give focus, but dreams give power. – John Maxwell"
,"Success is what happens after you have survived all of your disappointments."
,"Focus on your goal. Don’t look in any direction but ahead."
,"Success is what happens after you have survived all of your disappointments."
,"Everything you’ve ever wanted is on the other side of fear. — George Addair"
,"You were put on this Earth to achieve your greatest self, to live out your purpose, and to do it courageously. – Steve Maraboli"
,"Whatever is worrying you right now, forget about it. Take a deep breath, stay positive and know that things will get better."
,"Life is 10% what happens to you and 90% how you react to it. – Charles R. Swindoll"
,"I learned that courage was not the absence of fear, but the triumph over it. The brave man is not he who does not feel afraid, but he who conquers that fear. – Nelson Mandela"
,"Don’t stop until you’re proud."
,"The best way to gain self-confidence is to do what you are afraid to do. – Swati Sharma"
,"Keep going. Everything you need will come to you at the perfect time."
,"You are so much more than what you are going through. – John Tew"
,"Strength doesn’t come from what you can do. It comes from overcoming the things you once thought you couldn’t. – Rikki Rogers"
,"Remember it’s just a bad day, not a bad life."
,"Do something today that your future self will thank you for."
,"Perfection is not attainable, but if we chase perfection we can catch excellence. — Vince Lombardi"
,"Push yourself because no one else is going to do it."
,"Hard times don’t create heroes. It is during the hard times when the ‘hero’ within us is revealed. – Bob Riley"
,"In times of difficulty, we often look around to find a source of inspiration and hope. – Verusha Singh"
,"Success is what comes after you stop making excuses. – Luis Galarza"
,"I’m not telling you it is going to be easy, I’m telling you it’s going to be worth it. – Art Williams"
,"All our dreams can come true, if we have the courage to pursue them. – Walt Disney"
,"You cannot fail at being yourself. – Wayne Dyer"
,"The path to success is to take massive, determined action. – Tony Robbins"
,"It takes courage to grow up and become who you really are. – E.E. Cummings"
,"Results happen over time, not overnight. Work hard, stay consistent, and be patient."
,"If it doesn’t challenge you, it won’t change you."
,"Pessimism leads to weakness, optimism to power. – William James"
,"Difficult roads always lead to beautiful destinations. – Zig Ziglar"
,"Be so good they can’t ignore you. – Steve Martin"
,"Hustle until you no longer have to introduce yourself."
,"When you know what you want, and want it bad enough, you’ll find a way to get it. – Jim Rohn"
,"Never give up. Great things take time. Be patient."
,"A problem is a chance for you to do your best. – Duke Ellington"
,"Never stop trying. Never stop believing. Never give up. Your day will come."
,"It is during our darkest moments that we must focus to see the light. — Aristotle Onassis"
,"Believe in yourself! Have faith in your abilities! Without a humble but reasonable confidence in your own powers you cannot be successful or happy. – Norman Vincent Peale"
,"Set a goal so big that you can’t achieve it until you grow into the person who can."
,"Keep going. Everything you need will come to you at the perfect time."
,"Our greatest glory is not in never falling, but in rising every time we fall. — Confucius"
,"You have to be at your strongest when you’re feeling at your weakest."
,"Passion first and everything will fall into place. – Holly Holm"
,"Believe in yourself. You are braver than you think, more talented than you know, and capable of more than you imagine. ― Roy T. Bennett"
,"Believe you can and you’re halfway there. – Theodore Roosevelt"
,"The only person you are destined to become is the person you decide to be. — Ralph Waldo Emerson"
,"Results happen over time, not overnight. Work hard, stay consistent, and be patient."
,"Success is what happens after you have survived all of your disappointments."
,"Courage is one step ahead of fear. – Coleman Young"
,"We must embrace pain and burn it as fuel for our journey. – Kenji Miyazawa"
,"The future belongs to those who believe in the beauty of their dreams. – Franklin D. Roosevelt"
,"Don’t think about what might go wrong. Think about what might go right."
,"Challenges are what make life interesting. Overcoming them is what makes them meaningful."
,"You don’t always get what you wish for. But you always get what you work for."
,"When you fear your struggles, your struggles consume you. When you face your struggles, you overcome them."
,"Choosing to be positive and having a grateful attitude is going to determine how you’re going to live your life. – Joel Osteen"
,"Life shrinks or expands in proportion to one’s courage. – Anaïs Nin"
,"Life is a process. We are a process. The universe is a process. – Anne Wilson Schaef"
,"Your hardest times often lead to the greatest moments of your life. Keep going. Tough situations build strong people in the end. – Roy T. Bennett"
,"Don’t downgrade your dream just to fit your reality. Upgrade your conviction to match your destiny. – John Assaraf"
,"You don’t find will power, you create it."
,"We don’t develop courage by being happy every day. We develop it by surviving difficult times and challenging adversity. – Barbara De Angelis"
,"It does not matter how slowly you go as long as you do not stop. – Confucius"
,"Pain is temporary. Quitting lasts forever. — Lance Armstrong"
,"You have to fight through some bad days to earn the best days of your life."
,"If you believe it will work out, you’ll see opportunities. If you believe it won’t, you will see obstacles. – Wayne Dyer"
,"If you want it, you’ll find a way. If you don’t, you’ll find an excuse."
,"The first and greatest victory is to conquer self. – Plato"
,"When you wish upon a star ...you might be happier the way you are. – Phyllis Sugar"
,"Every next level of your life will demand a different version of you."
,"Start where you are. Use what you have. Do what you can. – Arthur Ashe"
,"If you feel like giving up, just look back on how far you are already."
,"Don’t try to be perfect. Just try to be better than you were yesterday."
,"Perseverance is the hard work you do after you get tired of doing the hard work you already did. – Newt Gingrich"
,"The moment you doubt whether you can fly, you cease for ever to be able to do it. – J.M. Barrie"
,"You will never always be motivated. You have to learn to be disciplined."
,"It’s not about perfect. It’s about effort. – Jillian Michaels"
,"Look in the mirror. That’s your competition."
,"You don’t gain anything from stressing. Remember that."
,"Strength does not come from physical capacity. It comes from an indomitable will. – Mahatma Gandhi"
,"Make the most of yourself….for that is all there is of you. – Ralph Waldo Emerson"
,"Staying positive does not mean that things will turn out okay. Rather it is knowing that you will be okay no matter how things turn out"
,"Many people believe in God, but not many believe God. – Mark Dudley"
,"The pain you feel today will be the strength you feel tomorrow."
,"Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart. ― Roy T. Bennett"
,"Motivation is what gets you started. Habit is what keeps you going. – Jim Rohn"
,"If you get tired, learn to rest, not quit.",
]