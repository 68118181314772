import React from 'react'
import './splitter.css'

export default class Splitter extends React.Component {
    constructor() {
        super()
        this.state = {
            blocks: []
        }
        this.splitBlock = (block, index) => {
            const history = [...this.state.blocks]
            const blocks = this.state.blocks.map(
                (b, i) => i === index && b.content
                    ? ({...block, content: b.content.length === 0 ? [{component:"nye nye"}, {component:"nye nye"}] : [...b.content, {component:"nye nye"}]})
                    : b)
            this.setState({blocks,history})
        }
        this.removeSubBlock = ({block, index, parentIndex}) => {
            const blocks = this.state.blocks[parentIndex]
            const removedFrom = blocks.content.filter((_,i) => i !== index)
            const newBlocks = [...this.state.blocks]
            newBlocks[parentIndex] = removedFrom
            this.setState({blocks:newBlocks})
        }
        this.showInChild = (index, childIndex) => {
            const blocks = this.state.blocks[index]
            const s = "https://picsum.photos/1000/720/?blur"
            const shownIn = blocks.content.map(
                (b, i) => i === childIndex ? {...b, show:s} : b)
            const newBlocks = [...this.state.blocks]
            newBlocks[index] = shownIn
            this.setState({blocks:newBlocks})
        }
        this.showIn = (block, index, childIndex) => {
            if (childIndex) return this.showInChild(index, childIndex)
            const blocks = this.state.blocks.map(
                (b, i) => i === index ? {...b, show:"https://source.unsplash.com/random"} : b)
            this.setState({blocks:blocks})

        }
        this.makeBlocks = () => {
            const makeWidth = (count) => ((100 / count) + "%")
            const makeHeight = (count) => ((100 / count) + "%")
            return this.state.blocks.map((block, index) => {
                return (<div className="block" key={index} style={{width:makeWidth(this.state.blocks.length)}}>
                    <div className="block-controls">
                    <h2 onClick={() => this.splitBlock(block, index)}>Split</h2>
                    {
                        block.content && block.content.length === 0 && (
                            <h3 onClick={() => this.showIn(block, index)}>Show</h3>
                        )
                    }
                   
                    <h4 onClick={() => this.setState({blocks:this.state.blocks.filter((_,i) => i !== index)})}>Remove</h4>
                    </div>
                    {
                        block.show && (
                            <img alt={index} src={block.show} />
                        )
                    }
                    {block.content && block.content.map((subBlock, i) => (
                        <div className="sub-block" key={i} style={{height:makeHeight(block.content.length)}}>
                            
                        <h3 onClick={() => this.removeSubBlock({
                            block:subBlock,
                            index:i,
                            parentIndex:index})}>Remove</h3>
                        <div onClick={() => this.showIn(block, index, i)}>Show</div>

                            {
                                subBlock.show && (
                                    <img alt={index} src={subBlock.show} />
                                )
                            }
                        </div>
                    ))}
                </div>)
            })
        }
        this.controls = () => {
            return (
                <div className="splitter-controls">
                    {[
                        (Array.isArray(this.state.history)) && ({
                            label:"Fortryd", action: () => {
                                const regret = [...this.state.blocks]
                                const blocks = [...this.state.history]
                                this.setState({history:false,blocks,regret})
                            }
                        }),
                        {label:"Editing layout"}
                    ].map((cont, i) => (<h3 onClick={() => cont.action && cont.action()} key={i}>{cont.label}</h3>))}
                </div>
            )
        }
        this.addRootBlock = () => {
            return (
                <div onClick={() => this.setState({blocks:this.state.blocks.concat({content:[]})})} className="add-root-block" style={{height:window.innerHeight}}><h2>Add</h2></div>
            )
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.controls()}
                <div className="splitter" style={{height:window.innerHeight}}>{this.makeBlocks()}</div>
                {this.addRootBlock()}
            </React.Fragment>
        )
    }
}