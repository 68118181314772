import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Search from './Search';
import Splitter from './Splitter';
import Timeline from './Timeline';
import Page from './Page';
import Frontpage from './Frontpage';
import Colourz, {ManyColors} from './Colourz';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Frontpage />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
