import React from 'react';

export default class StyleEditor extends React.Component {
    constructor() {
        super()
        this.state = {
            style:false
        }
        this.setStylesheet = (stylesheet) => {
            var head = document.head;
            var link = document.createElement("link");
            
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "/communities/25/styles";
            
            head.appendChild(link);
            this.setState({
                stylesheet
            })
        }
        this.updateStyle = (cid) => {
            
            fetch('/communities/25/styles',{
                method:"post",
                body:this.state.style,
                headers: {
                    'content-type':"text/plain"
                }
            }).then(rsp => rsp.text()).then((data) => this.setStylesheet(data))
        }
        this.lookupElement = () => {
            const findElement = (e) => {
                console.log(e.target.tagName)
                console.log(e.target.innerText);
                this.setState({
                    lookupElement:false,
                    foundElement:{
                        tagName: e.target.tagName,
                        innerText: e.target.innerText
                    },
                    style: `${this.state.style}\n\n${e.target.tagName} {

                    }`
                })
                document.removeEventListener("click", findElement)
            }
            document.addEventListener("click", findElement)
            this.setState({lookupElement:true})
        }
        this.createElement = (element) => {
            return React.createElement(element.tagName, {}, element.innerText)
        }
    }
    componentDidMount() {
        fetch('/communities/25/styles').then(rsp => rsp.text()).then((data) => this.setState({style:data}))
    }
    render() {
        return (
            <div>
                {
                    this.state.foundElement && (
                        <React.Fragment>
                        <div style={{padding:"20px",background:"#fff",borderRadius:"5px"}}>
                            {this.createElement(this.state.foundElement)}
                        </div>
                        <textarea value={this.state.style} style={{padding:"20px"}} rows="20" cols="80" onChange={(e) => this.setState({style:e.target.value})}></textarea>
                        <input style={{display:"block",width:"250px",margin:"20px auto",padding:"20px"}} type="submit" value="Update style" onClick={() => this.updateStyle(this.state.style)} />
                        </React.Fragment>
                    )
                }
            {
                this.state.lookupElement
                ? (<button style={{display:"block",width:"250px",margin:"20px auto",padding:"20px"}}>&times;</button>)
                : (<button style={{display:"block",width:"250px",margin:"20px auto",padding:"20px"}} onClick={() => this.lookupElement()}>Find Element</button>)
            }
            {
                ! this.state.foundElement && (
                    <React.Fragment>
                    <textarea value={this.state.style} style={{padding:"20px"}} rows="20" cols="80" onChange={(e) => this.setState({style:e.target.value})}></textarea>
                    <input style={{display:"block",width:"250px",margin:"20px auto",padding:"20px"}} type="submit" value="Update style" onClick={() => this.updateStyle(this.state.style)} />
                    </React.Fragment>
                )
            }
            </div>
        )
    }
}